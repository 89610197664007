var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-safari",
  body: function iconSafari() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 16 16\">" + "<g>" + "<path d=\"M8.500,16.000 C4.358,16.000 1.000,12.642 1.000,8.500 C1.000,6.753 1.622,5.166 2.624,3.891 C2.427,3.956 2.219,4.000 2.000,4.000 C0.895,4.000 -0.000,3.105 -0.000,2.000 C-0.000,0.895 0.895,0.000 2.000,0.000 C3.105,0.000 4.000,0.895 4.000,2.000 C4.000,2.219 3.956,2.427 3.891,2.624 C5.166,1.622 6.753,1.000 8.500,1.000 C12.642,1.000 16.000,4.358 16.000,8.500 C16.000,12.642 12.642,16.000 8.500,16.000 ZM2.000,1.000 C1.448,1.000 1.000,1.448 1.000,2.000 C1.000,2.552 1.448,3.000 2.000,3.000 C2.552,3.000 3.000,2.552 3.000,2.000 C3.000,1.448 2.552,1.000 2.000,1.000 ZM8.500,3.000 C5.462,3.000 3.000,5.462 3.000,8.500 C3.000,11.538 5.462,14.000 8.500,14.000 C11.538,14.000 14.000,11.538 14.000,8.500 C14.000,5.462 11.538,3.000 8.500,3.000 ZM5.768,3.773 C5.768,3.773 10.596,7.294 10.596,7.294 C10.596,7.294 11.231,13.236 11.231,13.236 C11.231,13.236 6.403,9.715 6.403,9.715 C6.403,9.715 5.768,3.773 5.768,3.773 ZM7.194,9.258 C7.194,9.258 9.805,7.751 9.805,7.751 C9.805,7.751 6.798,5.558 6.798,5.558 C6.798,5.558 7.194,9.258 7.194,9.258 Z\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "</g>" + "</svg>";
  }
};
export default exports;